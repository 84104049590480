import {getBaseDataDivisor, getDataPoint} from "../../../../services/StatisticsService";

export const InvoiceStatisticsBarData = ({
    context,
    invoices,
    mapInvoiceDateToFirstDayOfMonth,
    maxDate,
    minDate,
    selectedContractItems,
    selectedTab
 }) => {

    let data = [];
    let dataKeys = new Set();

    const sortedInvoices = invoices?.filter(invoice => {

        const invoiceDateMappedToFirstDay = mapInvoiceDateToFirstDayOfMonth(invoice.invoiceDate).getTime();

        return invoiceDateMappedToFirstDay >= minDate && invoiceDateMappedToFirstDay <= maxDate;

    })?.sort((a, b) => a.invoiceDate - b.invoiceDate);

    if (!minDate || !maxDate || selectedContractItems.length === 0
        || !sortedInvoices || sortedInvoices.length === 0) {
        return [null, null];
    }

    sortedInvoices.forEach(invoice => {
        const numberToItemMap = new Map();
        const nameToItemMap = new Map();
        invoice.invoiceItems.forEach((invoiceItem) => {
            if (!!invoiceItem.articleNumber) {
                numberToItemMap.set(invoiceItem.articleNumber, invoiceItem);
            } else {
                nameToItemMap.set(invoiceItem.articleName, invoiceItem);
            }
        });

        const dataPoints = {invoiceId: invoice.id};

        selectedContractItems.forEach(selectedItem => {
            const divisor = getBaseDataDivisor(context, invoice.id, invoices, selectedTab);

            selectedItem.articleNumbers.forEach((articleNumber) => {
                let invoiceItem = numberToItemMap.get(articleNumber);
                dataPoints[articleNumber] = getDataPoint(invoiceItem, divisor, selectedTab);
                dataKeys.add(articleNumber);
            });

            if (!selectedItem.articleNumbers) {
                const key = selectedItem.item.name;
                dataKeys.add(key);
                let invoiceItem = nameToItemMap.get(key);
                dataPoints[key] = getDataPoint(invoiceItem, divisor, selectedTab);
            }
        });
        data.push(dataPoints);
    });
    return [data, dataKeys];
}
