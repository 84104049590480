import React, {Component} from 'react';
import {injectIntl} from 'react-intl';

import '../apps/App.css';
import '../../css/ProjectDashboard.css';

import {PROJECT_STATUS_IN_PROGRESS, PROJECT_STATUS_IS_ACCEPTED} from '../../util/Constants';
import TexisionDialog from '../uiLibrary/TexisionDialog';
import {GeneralContext} from "../contexts/GeneralContext";
import { postAsyncCatch, putAsyncCatch } from '../../services/BackendService';
import { withSnackbar } from 'notistack';
import {updateProject} from "../../services/ProjectService";
import OperationPanel from "./OperationPanel";
import {setOperation, setOperationAndProject} from "../../services/OperationService";
import {isTender} from "../../util/Util";

class ProjectCreateOrUpdateDialog extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        if (this.props.project) {
            this.state = this.propsData();
            this.projectId = this.props.project.id;
        } else {
            this.state = this.emptyData();
            this.projectId = null;
        }
    }

    emptyData = () => {

        let profileIds = [];

        const defaultProfileId = this.props.allProfiles?.find(p => p.isDefault)?.id;
        if (defaultProfileId !== null && defaultProfileId !== undefined) {
            profileIds.push(defaultProfileId);
        }

        const projectStatus = isTender() ? PROJECT_STATUS_IN_PROGRESS : PROJECT_STATUS_IS_ACCEPTED;

        return {
            projectName: "",
            isPrivate: true,
            version: 0,
            status: projectStatus,
            profileIds: profileIds
        };
    }

    propsData = () => {
        const { operation, project } = this.props;
        return {
            profileIds: operation.profileIds,
            isPrivate: operation.isPrivate,
            version: project.version,
            status: project.status,
            projectName: project.name
        };
    }

    checkDialogButtonDisabled = () => {
        return !this.state.projectName || !this.state.profileIds?.length;
    }

    handleAbort = () => {
        this.setState(this.emptyData());
        this.props.closeDialog();
    }

    handleSaveOrUpdate = async() => {
        const operation = {
            id: this.props.operation?.id,
            version: this.props.operation?.version ?? 0,
            isPrivate: this.state.isPrivate,
            profileIds: this.state.profileIds
        };

        const project = {
            id: this.projectId,
            name: this.state.projectName,
            version: this.state.version,
            status: this.state.status
        };

        let operationResult;
        let projectResult;
        if (project.id !== null && project.id !== undefined) {
            operation.activeProject = this.props.operation.activeProject;
            operation.workingProject = this.props.operation.workingProject;
            const hideSuccess = true;
            const projectSuccess = await updateProject(this.context, this.props, project);
            operationResult = await putAsyncCatch(this.context, "/operation/" + operation.id, operation, this.props, hideSuccess);
            if (!projectSuccess) {
                projectResult = null;
            } else if (operationResult?.activeProject?.id === project.id) {
                projectResult = operationResult.activeProject;
            } else if (operationResult?.workingProject?.id === project.id) {
                projectResult = operationResult.workingProject;
            }
            
        } else {
            operation.activeProject = project;
            operationResult = await postAsyncCatch(this.context, "/operation", operation, this.props);
            projectResult = operationResult?.workingProject;
            // user has to be loaded after creating new projects so the list of assigned operations is up-to-date
            await this.context.loadUser();
        }

        if (operationResult && projectResult) {
            if (isTender()) {
                await setOperationAndProject(this.context, operationResult, projectResult);
            } else {
                await setOperation(this.context, operationResult);
            }
            this.props.closeDialog();
            await this.context.reloadAppData();
            this.setState(this.emptyData());
        }
    }

    render() {
        const isEditing = this.projectId !== null && this.projectId !== undefined;
        return (
            <TexisionDialog
                open={this.props.showDialog}
                size="lg"
                hasNoTitle={true}
                actionId={isEditing? "commons.apply.button" : "commons.create.button"}
                cancelId="commons.cancel.button"
                onAction={() => this.handleSaveOrUpdate()}
                onCancel={() => this.handleAbort()}
                actionDisabled={this.checkDialogButtonDisabled()}
                content={<OperationPanel
                    projectId={this.projectId}
                    projectName={this.state.projectName}
                    onNameChange={(projectName) => this.setState({projectName})}
                    isPrivate={this.state.isPrivate}
                    onIsPrivateChange={(isPrivate) => this.setState({isPrivate})}
                    profileIds={this.state.profileIds}
                    onProfileIdsChange={(profileIds) => this.setState({profileIds: profileIds})}
                    />}
            />
        );
    }
}

export default withSnackbar(injectIntl(ProjectCreateOrUpdateDialog));
