import {getAsync, postAsyncCatch} from "./BackendService";
import {getActiveOperation} from "./OperationService";
import {getMonthValue, INTERVAL_TYPE_KEY, INTERVAL_TYPES, MONTHS} from "../util/Constants";
import {isOfferUser} from "./UserService";

export async function getBaseData(operationId) {
    const response = await getAsync("/statistics/basedata/" + operationId);
    if (response?.status === 200) {
        return response.data;
    }
}

export async function createBaseDataSet(context, props, baseData, intervalType) {
    if (baseData.bedsByStation) {
        let bedsByStationList = [];
        for (let [deliveryAddressId, bedCount] of baseData.bedsByStation) {
            bedsByStationList.push({deliveryAddressId, bedCount});
        }
        baseData.bedsByStation = bedsByStationList;
    }
    return await postAsyncCatch(context, "/statistics/basedata/" + getActiveOperation(context).id + "/" + intervalType, baseData, props);
}

export function interpolationFunction(x, minX, maxX, firstY, averageY) {
    if (maxX - minX === 0) {
        return firstY;
    }
    return firstY + (averageY - firstY) / ((maxX - minX) / 2) * (x - minX);
}

export function getExtrapolatedDataPoints(condition, data, key, color, nameId, tabKey, getLine) {
    let extrapolatedData = [];
    let extrapolatedLines = [];
    if (condition && data.filter(d => d[key + tabKey] !== null && d[key + tabKey] !== undefined).length) {
        let sumY = 0.0;
        const allData = data.filter(d => d[key + tabKey] !== null && d[key + tabKey] !== undefined)
            .map(d => {return {x: d.time, y: d[key + tabKey]}}).sort((d1, d2) => d1.x - d2.x);
        const firstValue = allData[0].y;
        const minTime = Math.min.apply(Math, allData.map(d => d.x));
        const maxTime = Math.max.apply(Math, allData.map(d => d.x));
        const maxDate = new Date(maxTime);
        for (let i = 0; i < allData.length; i++) {
            sumY += allData[i].y;
        }
        const averageValue = sumY / allData.length;

        for (let j = 1; j < 7; j++) {
            let nextMonthTime = new Date(maxDate.getFullYear(), maxDate.getMonth() + j, 1).getTime();
            const extrapolatedDataPoint = {time: nextMonthTime, extrapolated: true};
            extrapolatedDataPoint[key + tabKey] = interpolationFunction(nextMonthTime, minTime, maxTime, firstValue, averageValue);
            extrapolatedData.push(extrapolatedDataPoint);
            if (getLine) {
                extrapolatedLines.push(getLine(
                    nextMonthTime,
                    key + tabKey,
                    nameId,
                    color,
                    true
                ));
            }
        }
    }
    if (getLine) {
        return [extrapolatedData, extrapolatedLines];
    } else {
        return extrapolatedData;
    }
}

export function accumulateData(data, keys, xKey) {
    if (!data || !keys) {
        return [];
    }
    let accumulatedData = [];
    for (let i = 0; i < data.length; i++) {
        let accumulatedDataPoint = {};
        accumulatedDataPoint[xKey] = data[i][xKey];
        accumulatedDataPoint.extrapolated = data[i].extrapolated;
        // filter out the key of the xAxis value and the extrapolation key to only get the category keys
        for (let key of [...keys].filter(k => k !== xKey && k !== "extrapolated")) {
            accumulatedDataPoint[key] = data[i][key];
            if (i > 0) {
                accumulatedDataPoint[key] += accumulatedData[i - 1][key];
            }
        }
        accumulatedData.push(accumulatedDataPoint);
    }
    return accumulatedData;
}

export function getInvoiceDateByInvoiceId(invoiceId, invoices) {
    const invoiceDate = invoices?.find(i => i.id === invoiceId)?.invoiceDate;
    return new Date(invoiceDate);
}

function calculateDataPoint(invoiceItem, divisor, selectedTab) {
    if (selectedTab === "price") {
        return invoiceItem?.price ?? 0;
    } else if (selectedTab === "pricePerCase" || selectedTab === "pricePerDay") {
        return (invoiceItem?.price ?? 0) * (invoiceItem?.amount ?? 0) / divisor;
    } else {
        return (invoiceItem?.amount ?? 0) / divisor;
    }
}

export function getDataPoint(invoiceItem, divisor, selectedTab) {
    if (divisor === null || divisor === 0) {
        return 0;
    }
    return calculateDataPoint(invoiceItem, divisor, selectedTab);
}

export function getBaseDataDivisor(context, invoiceId, invoices, selectedTab) {
    const baseData = context.appData.baseData;
    const invoiceDate = getInvoiceDateByInvoiceId(invoiceId, invoices);
    const baseDataEntry = baseData.find(d => d.month === invoiceDate.getMonth() + 1 && d.year === invoiceDate.getFullYear());
    if (selectedTab === "price" || selectedTab === "amount") {
        return 1;
    } else if (!baseDataEntry) {
        return null;
    } else if (selectedTab === "pricePerDay" || selectedTab === "amountPerDay") {
        return baseDataEntry.careDays;
    } else if (selectedTab === "pricePerCase" || selectedTab === "amountPerCase") {
        return baseDataEntry.inpatientCases;
    } else {
        return 1;
    }
}

export async function hasMissingBaseData(context) {
    if (isOfferUser(context.currentUser)) {
        return false;
    }
    const intervalType = context.getUserStateValue(INTERVAL_TYPE_KEY);
    const currentDate = new Date();
    // + 1 to fit our month scale (1 = january)
    const currentMonth = currentDate.getMonth() + 1;
    const currentYear = currentDate.getFullYear();

    const contract = await context.getActiveContract();
    const startOfServiceDate = new Date(contract.startOfServiceDate);
    const startOfServiceYear = startOfServiceDate.getFullYear();
    const startOfServiceMonth = startOfServiceDate.getMonth() + 1;

    if (startOfServiceDate >= currentDate) {
        return false;
    }

    switch (intervalType) {
        case INTERVAL_TYPES.MONTHLY:
            if (startOfServiceYear === currentYear && startOfServiceMonth === currentMonth) {
                return false;
            }
            break;
        case INTERVAL_TYPES.QUARTERLY:
            if (startOfServiceYear === currentYear && getQuarter(startOfServiceMonth) === getQuarter(currentMonth)) {
                return false;
            }
            break;
        case INTERVAL_TYPES.YEARLY:
            if (startOfServiceYear === currentYear) {
                return false;
            }
            break;
        default:
            break;
    }

    const baseData = context.appData.baseData;
    if (!baseData) {
        return true;
    }

    switch (intervalType) {
        case INTERVAL_TYPES.MONTHLY:
            const previousMonth = currentMonth === 1 ? 12 : currentMonth - 1;
            return !baseData.find(bd => bd.month === previousMonth && bd.year === currentYear - 1);
        case INTERVAL_TYPES.QUARTERLY:
            if (currentMonth >= 1 && currentMonth <= 3) {
                return !baseData.find(bd => bd.year === currentYear - 1 && bd.month >= 10 && bd.month <= 12);
            } else if (currentMonth >= 4 && currentMonth <= 6) {
                return !baseData.find(bd => bd.year === currentYear && bd.month >= 1 && bd.month <= 3);
            } else if (currentMonth >= 7 && currentMonth <= 9) {
                return !baseData.find(bd => bd.year === currentYear && bd.month >= 4 && bd.month <= 6);
            } else if (currentMonth >= 10 && currentMonth <= 12) {
                return !baseData.find(bd => bd.year === currentYear && bd.month >= 7 && bd.month <= 9);
            }
            return true;
        case INTERVAL_TYPES.YEARLY:
            return !baseData.find(bd => bd.year === currentYear - 1);
        default:
            return true;
    }
}

export function getSelectableBaseDataQuarters(context, year) {
    let quarters = [];
    for (let quarter of [1, 2, 3, 4]) {
        if (context.appData.baseData?.find(bd => bd.year === year && isMonthInQuarter(bd.month, quarter))) {
            quarters.push(quarter);
        }
    }
    return quarters;
}

export function getSelectableBaseDataMonths(context, year) {
    let months = [];
    for (let month of Object.values(MONTHS)) {
        if (context.appData.baseData?.find(bd => bd.year === year && bd.month === getMonthValue(month))) {
            months.push(month);
        }
    }
    return months;
}

export function getSelectableBaseDataYears(context) {
    return [...new Set(context.appData.baseData?.map(d => d.year) ?? [])].sort((y1, y2) => y1 < y2);
}

export function isMonthInQuarter(month, quarter) {
    return (quarter === 1 && month >= 1 && month <= 3)
        || (quarter === 2 && month >= 4 && month <= 6)
        || (quarter === 3 && month >= 7 && month <= 9)
        || (quarter === 4 && month >= 10 && month <= 12);
}

export function getQuarter(month) {
    if (month >= 1 && month <= 3) return 1;
    if (month >= 4 && month <= 6) return 2;
    if (month >= 7 && month <= 9) return 3;
    if (month >= 10 && month <= 12) return 4;
}
