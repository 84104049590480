import {APP_TYPE_KEY, LAST_OPERATION_ID, LICENSE_TYPES, TENDER_APP} from "../util/Constants";
import {deleteAsyncCatch, getAsync, getAsyncCatch, postAsyncCatch, putAsyncCatch} from "./BackendService";
import {formatDate} from "../util/DocumentUtil";
import {getActiveOperation} from "./OperationService";
import {getPriceOfferByProjectId} from "./PriceOfferService";
import {formatPrice, isTender} from "../util/Util";

export async function refreshSubscriptionPrices(context, props) {
    await postAsyncCatch(context,"/cron/checkSubscriptionPeriod", null, props);
}

export async function checkCoupon(context, props, promoCode, licenseType) {
    const errorTextMap = new Map();
    errorTextMap.set(404, "paymentService.checkCoupon.404");
    errorTextMap.set(204, "paymentService.checkCoupon.204");
    errorTextMap.set(410, "paymentService.checkCoupon.410");
    errorTextMap.set(405, "paymentService.checkCoupon.405");
    errorTextMap.set(226, "paymentService.checkCoupon.226");
    errorTextMap.set(503, "paymentService.checkCoupon.503");
    const operationId = parseInt(localStorage.getItem(LAST_OPERATION_ID));
    const coupon = await getAsyncCatch(context, "/payment/" + operationId + "/coupon?promoCode=" + promoCode + "&licenseType=" + licenseType,
        props, errorTextMap);
    return coupon ? coupon : null;
}

export function getAllSucceededPayments(context) {
    const payments = context.payments;
    if (!payments?.length) {
        return [];
    }
    return payments.filter(p => p.paymentIntent?.status === "succeeded" ||  p.paymentIntent?.status === "processing");
}

export function getActivePaymentMethodType(context, licenseType) {
    if (!licenseType) {
        return null;
    }
    let operation = getActiveOperation(context);
    let paymentConfiguration =  new Map(Object.entries(operation?.paymentConfiguration));
    return paymentConfiguration.get(licenseType);
}

export function latestActivePayment(context, licenseType) {
    const payments = context.payments;
    if (!payments?.length) {
        return null;
    } else {
        const allPackages = payments.filter(p => p.licenseType === licenseType
            && (p.paymentIntent?.status === "succeeded" || p.paymentIntent?.status === "processing" ||
                p.invoice?.status === "open" || p.invoice?.status === "paid" ||
                p.subscription?.status === "active" || p.subscription?.status === "processing" ||
                (p.subscription?.status === "trialing" && p.subscription.collectionMethod !== "send_invoice")));

        if (licenseType === LICENSE_TYPES.COOPERATION_LICENSE) {
            // cooperation license is active if status is active or trialing
            // and if the pending setup is succeeded or null which means the user has paid
            // or the default payment method is set, which means the user will pay
            const activeCooperationLicense = allPackages.find(p =>
                (p.subscription?.status === "active" || p.subscription?.status === "processing" || p.subscription?.status === "trialing")
                && (!!p.subscription.defaultPaymentMethod
                    || p.subscription.pendingSetupIntentObject?.status === "succeeded"
                    || p.subscription.pendingSetupIntentObject?.status === "processing"
                    || !p.subscription.pendingSetupIntentObject));
            if (activeCooperationLicense) {
                return activeCooperationLicense;
            }
        } else {
            const activeLicense = allPackages.find(p => p);
            if (activeLicense) {
                return activeLicense;
            }
        }
        return null;
    }
}

export function getLatestPaymentStatus(context, licenseType) {
    const payments = context.payments;
    if (!payments?.length) {
        return null;
    } else {
        const p = payments.filter(p => p.licenseType === licenseType).find(p => p);
        return p?.subscription?.status ?? p?.paymentIntent?.status;
    }
}

export function getUnpaidSubscriptionPayment(context) {
    const payments = context.payments;
    if (!payments?.length) {
        return null;
    }
    return payments.find(p => p.subscription?.status === "past_due" || p.subscription?.pendingSetupIntentObject?.status === "requires_payment_method");
}

export function getPaymentByPriceId(context, priceId) {
    const payments = context.payments;
    if (!payments?.length || priceId === null || priceId === undefined) {
        return null;
    }
    return payments.find(p => p.priceId === priceId);
}

export async function resubscribe(context, props) {
    const operationId = parseInt(localStorage.getItem(LAST_OPERATION_ID));
    const subscription = await putAsyncCatch(context, "/payment/subscription/" + operationId, null, props, true);
    return subscription ? subscription : null;
}

export async function cancelSubscription(context, props) {
    const operationId = parseInt(localStorage.getItem(LAST_OPERATION_ID));
    return await deleteAsyncCatch(context, "/payment/subscription/" + operationId, props, null, true);
}

export async function createPayment(context, props, licenseType, packageType, priceId, offerId, couponId) {
    const userId = context.currentUser.id;
    const operationId = parseInt(localStorage.getItem(LAST_OPERATION_ID));
    const operationPayment = {
        licenseType, packageType, operationId, userId, priceId, coupon: {id: couponId}
    };
    return await postAsyncCatch(context, "/payment/" + operationId + (offerId ? ("/" + offerId) : ""), operationPayment, props, true);
}

export async function updatePayment(context, props, licenseType, packageType, priceId, offerId, paymentId, couponId) {
    const userId = context.currentUser.id;
    const operationId = parseInt(localStorage.getItem(LAST_OPERATION_ID));
    const operationPayment = {
        id: paymentId, licenseType, packageType, operationId, userId, priceId, coupon: {id: couponId}
    };
    return await postAsyncCatch(context, "/payment/" + operationId + (offerId ? ("/" + offerId) : ""), operationPayment, props, true);
}

export async function deletePayment(context, props, licenseType, operationId) {
    const path = "/payment/" + operationId + "/" + licenseType;
    return await deleteAsyncCatch(context, path, props, null, true)
}

export async function sendPaymentConfirmationMail(context, props, licenseType, operationId) {
    return await postAsyncCatch(context, "/payment/confirm/" + operationId + "/" + licenseType, null, props, true);
}

export async function sendInvoice(context, props, licenseType, operationId, address) {
    return await postAsyncCatch(context, "/payment/invoice/" + operationId + "/" + licenseType, address, props, true);
}

export async function getProductsAndPrices(context, props) {
    return await getAsyncCatch(context, "/payment/products", props);
}

export async function getPaymentsAndEvents(context, operationId) {
    const currentOperationId = operationId ?? localStorage.getItem(LAST_OPERATION_ID);
    if (currentOperationId === null || currentOperationId === undefined || currentOperationId === "null" || currentOperationId === "undefined") {
        return;
    }
    let [paymentsResponse, eventsResponse] = await Promise.all([
        getAsync("/payment/" + currentOperationId),
        getAsync("/operation/events/" + currentOperationId)
    ]);

    if (paymentsResponse?.status === 200) {
        context.onPaymentsLoaded(paymentsResponse.data);
    }

    if (eventsResponse?.status === 200) {
        context.onOperationEventsLoaded(eventsResponse.data);
    } else {
        context.onOperationEventsLoaded(null);
    }
}

// Right now subscriptions have a recurring interval of a month in stripe,
// but shall be treated as yearly subscriptions
export function getSubscriptionEndDate(intl, subscription, price, subtractDay) {
    const trialEnd = subscription?.trialEnd;
    const currentPeriodStart = subscription?.currentPeriodStart;
    if (!trialEnd && !currentPeriodStart) {
        return "";
    }
    // if there is a trial and the trial end is in the future, the cancel conditions must be
    // referenced to this date instead of the current period start
    const referenceDate = trialEnd && trialEnd*1000 > new Date().getTime() ? new Date(trialEnd*1000) : new Date(currentPeriodStart*1000);
    const currentTime = new Date().getTime();
    for (let i = 1; true; i++) {
        let latestCancelAt;
        let endTime;
        switch (getRecurringIntervalInMonths(price)) {
            case 1:
                latestCancelAt = new Date(referenceDate.getFullYear(), referenceDate.getMonth() + i, referenceDate.getDate() - 1).getTime();
                endTime = new Date(referenceDate.getFullYear(), referenceDate.getMonth() + i, referenceDate.getDate() - (subtractDay ? 1 : 0));
                break;
            case 12:
            default:
                latestCancelAt = new Date(referenceDate.getFullYear() + i, referenceDate.getMonth() - 3, referenceDate.getDate()).getTime();
                endTime = new Date(referenceDate.getFullYear() + i, referenceDate.getMonth(), referenceDate.getDate() - (subtractDay ? 1 : 0));
                break;

        }
        if (currentTime < latestCancelAt) {
            return formatDate(intl, endTime);
        }
    }
}

// Right now subscriptions have a recurring interval of a month in stripe,
// but shall be treated as yearly subscriptions
export function getRecurringIntervalInMonths(price) {
    if (!price?.recurring?.interval) {
        return 0;
    }
    switch (price?.recurring?.interval) {
        case "year":
        case "month":
        default:
            return 12;
    }
}

// Right now subscriptions have a recurring interval of a month in stripe,
// but shall be treated as yearly subscriptions
export function getRecurringIntervalText(intl, price) {
    switch (getRecurringIntervalInMonths(price)) {
        case 0:
            return null;
        case 1:
            return intl.formatMessage({id: "payment.subscription.interval.month"});
        case 12:
        default:
            return intl.formatMessage({id: "payment.subscription.interval.year"});
    }
}

export function getSubscriptionExtension(intl, price) {
    switch (getRecurringIntervalInMonths(price)) {
        case 0:
            return null;
        case 1:
            return intl.formatMessage({id: "payment.subscription.interval.month"});
        case 12:
        default:
            return intl.formatMessage({id: "payment.subscription.interval.year"});
    }
}

export function getCancelPeriod(intl, price) {
    switch (getRecurringIntervalInMonths(price)) {
        case 0:
            return null;
        case 1:
            return intl.formatMessage({id: "payment.subscription.cancelPeriod.month"});
        case 12:
        default:
            return intl.formatMessage({id: "payment.subscription.cancelPeriod.year"});
    }
}

export function getBillingPeriod(intl, price) {
    switch (getRecurringIntervalInMonths(price)) {
        case 0:
            return null;
        case 1:
        case 12:
        default:
            return intl.formatMessage({id: "payment.subscription.billingPeriod.year"});
    }
}

export function getSubscriptionPeriod(intl, price) {
    switch (getRecurringIntervalInMonths(price)) {
        case 0:
            return null;
        case 1:
            return intl.formatMessage({id: "payment.subscription.interval.month"});
        case 12:
        default:
            return intl.formatMessage({id: "payment.subscription.interval.year"});
    }
}

export function getTrialText(intl, product) {
    if (!product?.metadata) {
        return "";
    } else if (isTender() && product.metadata.trialFromTender) {
        const trialMonths = parseInt(product.metadata.trialFromTender);
        if (!trialMonths || isNaN(trialMonths)) {
            return "";
        } else {
            return intl.formatMessage({id: "payment.subscription.trialNotice"}, {trialMonths});
        }
    } else if (!isTender() && product.metadata.trialFromCockpit) {
        const trialMonths = parseInt(product.metadata.trialFromCockpit);
        if (!trialMonths || isNaN(trialMonths)) {
            return "";
        } else {
            return intl.formatMessage({id: "payment.subscription.trialNotice"}, {trialMonths});
        }
    } else {
        return "";
    }
}

export function getTrialMonths(product) {
    let trialMonths;
    if (isTender() && product?.metadata?.trialFromTender) {
        trialMonths = parseInt(product.metadata.trialFromTender);
    } else if (localStorage.getItem(APP_TYPE_KEY) !== TENDER_APP && product?.metadata?.trialFromCockpit) {
        trialMonths = parseInt(product.metadata.trialFromCockpit);
    }
    if (!trialMonths || isNaN(trialMonths)) {
        return 0;
    } else {
        return trialMonths;
    }
}

export function getCancelAt(intl, subscription, subtractDay) {
    if (!intl || !subscription) {
        return "";
    }
    // last date is one day before automatic renewal
    return formatDate(intl, subscription.cancelAt*1000 - (subtractDay ? 86400000 : 0));
}

export function getCanceledAt(intl, subscription) {
    if (!intl || !subscription) {
        return "";
    }
    return formatDate(intl, subscription.canceledAt*1000);
}

export function getSubscriptionStartDate(intl, subscription) {
    if (!intl || !subscription?.trialEnd) {
        return "";
    }
    return formatDate(intl, subscription.trialEnd*1000);
}

export function disabledProductSelectTooltip(intl, licenseType, sumGross) {
    if (licenseType === LICENSE_TYPES.OFFER_LICENSE) {
        return intl.formatMessage({id: "payment.offer.disabled.tooltip"});
    } else if (licenseType === LICENSE_TYPES.COOPERATION_LICENSE && !sumGross) {
        return intl.formatMessage({id: "payment.subscribe.noActivePrice.tooltip"});
    }
    return "";
}

export function getSubscriptionPercent(price) {
    if (price?.unitAmount) {
        return price.unitAmount;
    } else if (price?.tiers?.length) {
        return price.tiers[0].unitAmount;
    } else {
        return 0;
    }
}

export async function getPricePerMonthByPriceOffer(context, props, licenseType, price) {
    let priceInEuro = 0;
    if (licenseType === LICENSE_TYPES.COOPERATION_LICENSE) {
        const projectId = getActiveOperation(context).activeProject?.id;
        const priceOfferResult = await getPriceOfferByProjectId(context, props, projectId);
        if (priceOfferResult?.priceOfferVos?.length) {
            const factor = getSubscriptionPercent(price)/100.0;
            priceInEuro = priceOfferResult.priceOfferVos[0].sumGross * factor / 12.0;
        }
    }
    return priceInEuro;
}

export function getNextSubscriptionPrice(subscription) {
    const nextPrice = (subscription?.items?.data[0].quantity ?? 0) / 100.0;
    const coupon = subscription?.discount?.coupon;

    if (!coupon) {

        return formatPrice(nextPrice);

    } else if (coupon.duration === "forever") {

        if (coupon.amountOff && coupon.amountOff/100 <= nextPrice) {
            return formatPrice(nextPrice - coupon.amountOff/100);
        } else if (coupon.percentOff && coupon.percentOff <= 100) {
            return formatPrice(nextPrice*(1-coupon.percentOff/100.0));
        } else {
            return formatPrice(nextPrice);
        }

    } else if (coupon.duration === "once" && subscription.status === "trialing") {

        if (coupon.amountOff && coupon.amountOff/100 <= nextPrice) {
            return formatPrice(nextPrice - coupon.amountOff/100);
        } else if (coupon.percentOff && coupon.percentOff <= 100) {
            return formatPrice(nextPrice*(1-coupon.percentOff/100.0));
        }

    } else if (coupon.duration === "repeating") {

        const couponEndTime = subscription.discount.end;
        if (!couponEndTime) {
            return formatPrice(nextPrice);
        }
        const couponEndDate = new Date(couponEndTime*1000);

        if (new Date().getTime() < new Date(couponEndDate.getFullYear(), couponEndDate.getMonth(), 1).getTime()) {
            if (coupon.amountOff && coupon.amountOff/100 <= nextPrice) {
                return formatPrice(nextPrice - coupon.amountOff/100);
            } else if (coupon.percentOff && coupon.percentOff <= 100) {
                return formatPrice(nextPrice*(1-coupon.percentOff/100.0));
            }
        } else {
            return formatPrice(nextPrice);
        }

    }
    return formatPrice(nextPrice);
}
