import { useContext } from "react";
import {FormattedMessage, useIntl} from "react-intl";
import { white } from "../../../../util/ColorTheme";
import {INVOICE_CONFLICT_TYPE, USE_ALTERNATE_ARTICLE_NAMES} from "../../../../util/Constants";
import {formatAmount, formatPrice} from "../../../../util/Util";
import { GeneralContext } from "../../../contexts/GeneralContext";
import {getActiveOperation} from "../../../../services/OperationService";
import {getBaseDataDivisor} from "../../../../services/StatisticsService";

const InvoiceStatisticsTooltip = ({
    active,
    activeKey,
    payload, 
    label, 
    getXAxisTickLabel, 
    selectedTab, 
    invoices
}) => {

    const intl = useIntl();
    const context = useContext(GeneralContext);

    const currency = getActiveOperation(context).activeProject.currency;

    if (active) {

        const articleNumberAndPriceOrAmount = payload
            .map(p => [p.dataKey, p.value])
            .find(([dataKey, value]) => dataKey === activeKey);

        if (!articleNumberAndPriceOrAmount) {
            return null;
        }

        const articleNumber = articleNumberAndPriceOrAmount[0];

        const invoice = invoices?.find(i => i.id === label);
        const invoiceItem = invoice?.invoiceItems?.find(item => item.articleNumber === articleNumber || item.articleName === articleNumber);

        if (selectedTab === "pricePerDay" || selectedTab === "pricePerCase"
            || selectedTab === "amountPerDay" || selectedTab === "amountPerCase") {
            const divisor = getBaseDataDivisor(context, invoice.id, invoices, selectedTab);
            if (divisor === null || divisor === 0) {
                return (
                    <div style={{backgroundColor: white, border: "1px solid grey", padding: 15}}>
                        <FormattedMessage id="cockpit.statistics.invoices.noBaseData"/>
                    </div>
                );
            }
        }

        let priceOrAmount;

        if (selectedTab.includes("price")) {
            if (invoiceItem?.conflictType === INVOICE_CONFLICT_TYPE.MISSING_ARTICLE) {
                priceOrAmount = intl.formatMessage({id: "cockpit.statistics.price.missingArticle"});
            } else {
                priceOrAmount = formatPrice(articleNumberAndPriceOrAmount[1]) + " " + currency;
            }
        } else if (selectedTab.includes("amount")) {
            if (invoiceItem?.conflictType === INVOICE_CONFLICT_TYPE.MISSING_ARTICLE) {
                priceOrAmount = intl.formatMessage({id: "cockpit.statistics.amount.missingArticle"});
            } else {
                priceOrAmount = formatAmount(articleNumberAndPriceOrAmount[1]);
            }
        }

        let articleName;
        if (!!context.getUserStateValue(USE_ALTERNATE_ARTICLE_NAMES) && invoiceItem?.alternateDescription) {
            articleName = invoiceItem.alternateDescription;
        } else {
            articleName = invoiceItem?.articleName;
        }

        return (
            <div style={{backgroundColor: white, border: "1px solid grey", padding: 15}}>

                {getXAxisTickLabel(label)}

                <>
                    <p>
                        {
                            articleName
                            + (invoiceItem?.conflictType !== INVOICE_CONFLICT_TYPE.UNKNOWN_ARTICLE 
                                ? " (" + articleNumber + "): "
                                : ": ") 
                            + priceOrAmount
                        }
                    </p>
                </>
            </div>
        );
    }
    return null;
}

export {
    InvoiceStatisticsTooltip
}
