import {Grid, Typography} from "@material-ui/core";
import React, { Component } from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import {texisionGray, white} from "../../../../util/ColorTheme";
import { CHART_FILTER_OPTIONS } from "../../../../util/Constants";
import { GeneralContext } from "../../../contexts/GeneralContext";
import { de } from "date-fns/locale";
import { InvoiceDiagramEmptyMessage } from "./InvoiceDiagramEmptyMessage";
import { StatisticsDatePickers } from "../StatisticsDatePickers";
import { InvoiceStatisticsTabs } from "./InvoiceStatisticsTabs";
import { InvoiceStatisticsSelectButton } from "./InvoiceStatisticsSelectButton";
import { InvoiceStatisticsItemSelection } from "./InvoiceStatisticsItemSelection";
import {getActiveOperation} from "../../../../services/OperationService";
import ChartTypeSelection from "../ChartTypeSelection";
import InvoiceStatisticsLineChart from "./InvoiceStatisticsLineChart";
import InvoiceStatisticsBarChart from "./InvoiceStatisticsBarChart";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

class InvoiceStatisticsContainer extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);

        this.globalMinDate = null;
        this.globalMaxDate = null;

        this.state = {
            chartType: "line",
            accumulated: false,
            filter: CHART_FILTER_OPTIONS.ALL,
            showItemSelection: false,
            activeKey: null,
            activeInvoiceId: null,
            selectedContractItems: [],
            bufferedContractItems: [],
            selectedTab: "price",
            period: {min: {month: null, year: null}, max: {month: null, year: null}}
        }
    }

    componentDidMount() {
        this.initializePeriod();
    }

    componentDidUpdate(_, prevState) {
        if (prevState.selectedContractItems.length === 0 && this.state.selectedContractItems.length !== 0) {
            this.props.onDiagramDataChange(true);
        } else if (prevState.selectedContractItems.length !== 0 && this.state.selectedContractItems.length === 0) {
            this.props.onDiagramDataChange(false);
        }
    }

    initializePeriod = () => {
        const period = this.state.period;
        const allInvoiceDates = this.props.invoices
            ?.map(i => this.mapInvoiceDateToFirstDayOfMonth(i.invoiceDate))
            ?.sort((a,b) => a.getTime() - b.getTime());

        if (allInvoiceDates && allInvoiceDates.length > 0) {
            let minDate = allInvoiceDates[0];
            const maxDate = allInvoiceDates[allInvoiceDates.length-1];
            const minDateInMillis = minDate?.getTime();
            // set min date to maximum 12 months in the past
            const currentDateInMillis = new Date().getTime();
            if (minDateInMillis && currentDateInMillis > minDateInMillis && currentDateInMillis - minDateInMillis > 31557600000) {
                minDate = new Date(currentDateInMillis - 31557600000);
            }
            this.globalMinDate = new Date(minDate.getFullYear(), minDate.getMonth(), 1);
            this.globalMaxDate = new Date(maxDate.getFullYear(), maxDate.getMonth(), 1);
            period.min = {month: minDate.getMonth(), year: minDate.getFullYear()};
            period.max = {month: maxDate.getMonth(), year: maxDate.getFullYear()};
            this.setState({period});
        }
    }

    onChartTypeChange = (chartType) => {
        this.setState({chartType});
        this.props.onItemSelected(null, null);
    }

    isMonthDisabled = (date) => {
        if (!this.props.invoices || this.props.invoices.length === 0) {
            return true;
        }
        return !this.props.invoices
            .map(invoice => new Date(invoice.invoiceDate))
            .find(invoiceDate => invoiceDate.getMonth() === date.getMonth() 
                && invoiceDate.getFullYear() === date.getFullYear());
    }

    mapInvoiceDateToFirstDayOfMonth = (invoiceDateInMillis) => {
        const invoiceDate = new Date(invoiceDateInMillis);
        return new Date(invoiceDate.getFullYear(), invoiceDate.getMonth(), 1);
    }

    cancelSelection = () => {
        this.setState({bufferedContractItems: [...this.state.selectedContractItems], showItemSelection: false});
    }

    saveSelection = () => {
        this.setState({selectedContractItems: [...this.state.bufferedContractItems], showItemSelection: false});
        this.props.onItemSelected(null, null);
    }

    onItemChecked = (item, checked) => {
        let bufferedContractItems = this.state.bufferedContractItems;
        if (!checked) {
            bufferedContractItems = bufferedContractItems.filter(i => JSON.stringify(i) !== JSON.stringify(item));
        } else {
            bufferedContractItems.push(item);
        }
        this.setState({bufferedContractItems});
    }

    getXAxisTickLabel = (invoiceId) => {
        const invoiceDate = this.props.invoices?.find(i => i.id === invoiceId)?.invoiceDate;
        return new Date(invoiceDate).toLocaleString(de, {month: "long"}) + " " + new Date(invoiceDate).getFullYear();
    }

    onMinDateChange = (newDate) => {
        const min = {month: newDate.getMonth(), year: newDate.getFullYear()};
        const period = this.state.period;
        period.min = min;
        this.setState({period});
    }

    onMaxDateChange = (newDate) => {
        const max = {month: newDate.getMonth(), year: newDate.getFullYear()};
        const period = this.state.period;
        period.max = max;
        this.setState({period});
    }

    hasNoPeriod = () => {
        const period = this.state.period;
        return !!(
            (!period.min.month && period.min.month !== 0) 
            || !period.min.year 
            || (!period.max.month && period.max.month !== 0) 
            || !period.max.year
        );
    }

    render() {

        const { activeKey, activeInvoiceId, bufferedContractItems, filter, period, selectedContractItems, selectedTab, showItemSelection } = this.state;

        const { selectedInvoiceId, selectedInvoiceItem } = this.props;

        const activeProjectId = getActiveOperation(this.context)?.activeProject?.id;

        const hasNoContractItems = !this.props.projectIdToContractItemsMap 
            || this.props.projectIdToContractItemsMap.size === 0
            || !this.props.projectIdToContractItemsMap.get(activeProjectId) 
            || this.props.projectIdToContractItemsMap.get(activeProjectId).size === 0;
        const hasNoPeriod = this.hasNoPeriod();

        const chartProps = {
            activeKey: activeKey,
            accumulated: this.state.accumulated,
            activeInvoiceId: activeInvoiceId,
            getXAxisTickLabel: this.getXAxisTickLabel,
            invoices: this.props.invoices,
            mapInvoiceDateToFirstDayOfMonth: this.mapInvoiceDateToFirstDayOfMonth,
            maxDate: new Date(this.state.period.max.year, this.state.period.max.month, 1).getTime(),
            minDate: new Date(this.state.period.min.year, this.state.period.min.month, 1).getTime(),
            onHideTooltip: () => this.setState({activeKey: null, activeInvoiceId: null}),
            onItemSelected: (key, invoiceId) => this.props.onItemSelected(key, invoiceId),
            onShowTooltip: (activeKey, activeInvoiceId) => this.setState({activeKey, activeInvoiceId}),
            selectedContractItems: selectedContractItems,
            selectedInvoiceId: selectedInvoiceId,
            selectedInvoiceItem: selectedInvoiceItem,
            selectedTab: selectedTab
        }

        let Chart;
        if (this.state.chartType === "line") {
            Chart = InvoiceStatisticsLineChart;
        } else if (this.state.chartType === "bar") {
            Chart = InvoiceStatisticsBarChart;
        }

        return (
            <div style={{marginBottom: 20}}>

                <InvoiceStatisticsItemSelection
                    bufferedContractItems={bufferedContractItems}
                    cancelSelection={() => this.cancelSelection()}
                    filter={filter}
                    invoices={this.props.invoices}
                    onFilterChanged={(option) => this.setState({filter: option})}
                    onItemChecked={(contractItem, checked) => this.onItemChecked(contractItem, checked)}
                    projectIdToContractItemsMap={this.props.projectIdToContractItemsMap}
                    saveSelection={() => this.saveSelection()}
                    showItemSelection={showItemSelection}/>

                <Grid container>

                    <Grid item xs={12}>

                        <Grid container alignItems="center" spacing={2}>

                            <Grid item xs>
                                <InvoiceStatisticsSelectButton
                                    hasNoContractItems={hasNoContractItems}
                                    hasNoPeriod={hasNoPeriod}
                                    onClick={() => this.setState({showItemSelection: true})}/>
                            </Grid>

                            <Grid item>
                                <StatisticsDatePickers
                                    labelIdMin="cockpit.statistics.invoices.minDate"
                                    labelIdMax="cockpit.statistics.invoices.maxDate"
                                    disabled={!this.props.invoices || this.props.invoices.length === 0}
                                    globalMinDate={this.globalMinDate}
                                    globalMaxDate={this.globalMaxDate}
                                    minDate={new Date(period.min.year, period.min.month, 1)}
                                    maxDate={new Date(period.max.year, period.max.month, 1)}
                                    onMinDateChange={(newDate) => this.onMinDateChange(newDate)}
                                    onMaxDateChange={(newDate) => this.onMaxDateChange(newDate)}
                                    isMonthDisabled={(date) => this.isMonthDisabled(date)}/>
                            </Grid>

                        </Grid>
                        
                    </Grid>

                    <Grid 
                        item xs={12} 
                        style={{
                            backgroundColor: white, 
                            marginTop: 30, 
                            minWidth: 300, 
                            overflowY: "hidden", 
                            overflowX: selectedContractItems && selectedContractItems.length > 0 ? "scroll" : "hidden"
                        }}>

                        <InvoiceStatisticsTabs
                            selectedContractItems={selectedContractItems}
                            selectedTab={selectedTab}
                            onTabChange={(newValue) => this.setState({selectedTab: newValue})}/>

                        {!!selectedContractItems?.length && (
                            <div style={{paddingLeft: 40, paddingRight: 40}}>
                                <ChartTypeSelection
                                    accumulated={this.state.accumulated}
                                    onAccumulatedChange={(accumulated) => this.setState({accumulated})}
                                    chartType={this.state.chartType}
                                    onChartTypeChange={(chartType) => this.onChartTypeChange(chartType)}
                                />
                            </div>
                        )}

                        <InvoiceDiagramEmptyMessage
                            hasNoPeriod={this.hasNoPeriod()} 
                            invoices={this.props.invoices}
                            mapInvoiceDateToFirstDayOfMonth={this.mapInvoiceDateToFirstDayOfMonth}
                            projectIdToContractItemsMap={this.props.projectIdToContractItemsMap}
                            selectedContractItems={selectedContractItems}
                            selectedMaxDate={new Date(period.max.year, period.max.month, 1).getTime()}
                            selectedMinDate={new Date(period.min.year, period.min.month, 1).getTime()}/>

                        {!!selectedContractItems?.length && (
                            <>
                                <Chart {...chartProps}/>

                                <Typography
                                    variant="body2"
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        marginTop: 20,
                                        marginLeft: 30,
                                        marginBottom: 20,
                                        textAlign: "center"
                                    }}>
                                    <InfoOutlinedIcon style={{color: texisionGray, marginRight: 10}}/>
                                    <FormattedMessage id="statistics.legend.hint"/>
                                </Typography>
                            </>
                        )}

                    </Grid>

                </Grid>

            </div>
        );
    }
}

export default injectIntl(InvoiceStatisticsContainer);
