import React from 'react';
import { FormattedMessage } from 'react-intl';
import {ArticleImage} from './ArticleImage';
import {MONDAY, TUESDAY, WEDNESDAY, THURSDAY, FRIDAY, SATURDAY, SUNDAY, NO_OFFSETTING_SELECTED, SPLIT_PRICE} from './Constants';
import {texisionRed, texisionFontColorDark} from './ColorTheme';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import '../css/Util.css';

/*
* Util class for rendering sizes, images and categories into the article overview table and the individuell assortment table.
*/
export function renderCategories(value, category) {
    // sometimes the material table will return an object for a row (if not grouped) or a primitive value (if grouped)...
    let constantString = "";
    if (value === Object(value)) {

        switch (category) {
            case "SubCategory":
                constantString = "constants." + category + "." + value.subcategory;
                return <FormattedMessage id={constantString}/>
            case "Category":
                constantString = "constants." + category + "." + value.category;
                return <FormattedMessage id={constantString}/>
            case "ArticleCategory":
                constantString = "constants." + category + "." + value.articleCategory;
                return <FormattedMessage id={constantString}/>
            default:
                break;
        }
    } else {
        constantString = "constants." + category + "." + value;
        return <FormattedMessage id={constantString}/>
    }
}

export function renderImage(article, dialogCallback) {
    return <ArticleImage article={article} onClick={() => dialogCallback(article)}/>
 }

export function renderOffsetting(subCategoryOffsetting, articleCategoryOffsetting, intl) {
    let key = "constants.ArticleOffsetting." + subCategoryOffsetting;
    let textColor = articleCategoryOffsetting === NO_OFFSETTING_SELECTED ? texisionRed : texisionFontColorDark;
    return (
        <React.Fragment>
            <Typography style={{color: textColor}}>
                <FormattedMessage id={key}/>
                {articleCategoryOffsetting === SPLIT_PRICE ? " (" + intl.formatMessage({id: "constants.ArticleOffsetting." + SPLIT_PRICE}) + ")" : ""}
            </Typography>
        </React.Fragment>
    );
}

export function renderOffsettingAsString(subCategoryOffsetting, articleCategoryOffsetting, intl) {
    return intl.formatMessage({id: "constants.ArticleOffsetting." + subCategoryOffsetting})
        + (articleCategoryOffsetting === SPLIT_PRICE ? " (" + intl.formatMessage({id: "constants.ArticleOffsetting." + SPLIT_PRICE}) + ")" : "");
}

export function renderAssingedToDeliveryAddress(article, assignedArticles, intl) {

    for (let assingedArticles of assignedArticles){
        if (assingedArticles.articleId === article.id){
            return intl.formatMessage({id: "articleDeliveryPointAssignment.table.assigned"});
        }
    }
    return intl.formatMessage({id: "articleDeliveryPointAssignment.table.notAssigned"});
}


function getWeekDaySortIndex(weekday) {

    switch (weekday) {
        case MONDAY:
            return 1;
        case TUESDAY:
            return 2;
        case WEDNESDAY:
            return 3;
        case THURSDAY:
            return 4;
        case FRIDAY:
            return 5;
        case SATURDAY:
            return 6;
        case SUNDAY:
            return 7;
        default:
            break;
    }

}
function getWeekDayShortName(weekday, intl) {
    return intl.formatMessage({id: "constants.WeekdaysShort." + weekday});
}

function getTimeSchedule(weekday, timeslots, intl) {

    return (
        <div>
            <Grid container direction="column" alignItems="center" spacing={1}>
                <Grid key={weekday} item>
                        <Typography variant="body2">{intl.formatMessage({id: "constants.Weekdays." + weekday}) + ":"}</Typography>
                </Grid>
                {
                    timeslots.map(timeslot => {
                        return (
                            <Grid key={weekday+timeslot.fromHour} item style={{marginLeft: "20px"}}>
                                <Typography variant="body2">{timeslot.fromHour + " - " + timeslot.toHour + " " + intl.formatMessage({id: "constants.ShortTime"})}</Typography>
                            </Grid>
                        )
                    })
                }
            </Grid>
        </div>
    );
}

export function renderLogisticsType(deliveryAddress, intl) {
    return intl.formatMessage({id: "constants.DeliveryPickup." + deliveryAddress.logisticType})
}

export function renderTimeIntervals(deliveryAddress, intl) {

    let displayStrings = [];
    let timeIntervalsMap = new Map(Object.entries(deliveryAddress.deliveryTimes));

    for (let entry of timeIntervalsMap.entries()) {
        displayStrings.push(entry[0]);
    }

    displayStrings.sort(function (a, b) {
        let sortIndexA = getWeekDaySortIndex(a);
        let sortIndexB = getWeekDaySortIndex(b);
        if (sortIndexA < sortIndexB) {
            return -1;
        }

        if (sortIndexA > sortIndexB) {
            return 1;
        }
        return 0;

    });

    return(
        <div>
            <Grid container direction="row" spacing={1}>
                {
                    displayStrings.map(weekday => {
                        return(
                            <Grid key={weekday} item>
                                <Typography variant="body1" style={{marginLeft: "3px", display: 'inline', verticalAlign: 'middle'}}>{getWeekDayShortName(weekday, intl)}</Typography>
                                {
                                    (timeIntervalsMap.get(weekday).length >= 1) &&
                                    <Tooltip title={getTimeSchedule(weekday, timeIntervalsMap.get(weekday), intl)} aria-label={weekday}>
                                        <Icon className="icon-clock" style={{fontSize: "21pt", display: 'inline', verticalAlign: 'middle'}}/>
                                    </Tooltip>
                                }
                            </Grid>
                        );
                    })
                }
            </Grid>
        </div>
    );
}

export function renderAssignedArticleCount(allArticles, deliveryAddressId, intl) {

    if (allArticles) {
        let count = allArticles.filter(article => article.deliveryAddressId === deliveryAddressId).length;
        return count + " " + intl.formatMessage({id: "articleDeliveryPointAssignment.table.assignmentCount"});
    } else {
        return 0 + " " + intl.formatMessage({id: "articleDeliveryPointAssignment.table.assignmentCount"});
    }
}

export function renderProjectCount(user) {
    return user.operationIds.length;
}

export function renderChecked(article, callback, disabled) {
    return <Checkbox
        color="primary"
        checked={article.assigned}
        onChange={(e) => callback(article, e.target.checked)}
        inputProps={{ 'aria-label': 'primary checkbox'}}
        disabled={disabled}
    />
}

export function renderVolumeType(customerArticle, intl) {
    return intl.formatMessage({id: "constants.VolumeType." + customerArticle.volumeType});
}

export function renderFeatures(features, intl, i18nPrefix) {
    return features
        .map(feature => intl.formatMessage({id: i18nPrefix + feature.type}) + ": " + feature.value)
        .join(", ");
}

export function renderFeaturesWithoutType(features) {
    return features
            .map(feature => feature.value)
            .join(", ");
}

export const formatArticleNumbers = (articleNumbers) => {
    if (!articleNumbers || articleNumbers.length === 0) {
        return "";
    }
    let text = "";
    for (var i = 0; i < articleNumbers.length; i++) {
        text += articleNumbers[i];
        if (i < articleNumbers.length - 1) {
            text += ", ";
        }
    }
    return text;
}

export const formatCustomerArticleFeatures = (features, intl) => {
    if (!features || features.length === 0) {
        return "";
    }
    let text = "";
    for (let i = 0; i < features.length; i++) {
        if (features[i].value) {
            text += features[i].value;
            if (features[i].type) {
                text += " (" + intl.formatMessage({id: "constants.ArticleFeatureType." + features[i].type}) + ")";
            }
            if (i < features.length - 1) {
                text += ", ";
            }
        }
    }
    return text;
}

export const wordBreak = (text) => {
    return <div dangerouslySetInnerHTML={{__html: text.replace("{0}", "&shy;")}} />
}
