import { Line } from "recharts";
import {ColorProvider, white} from "../../../../util/ColorTheme";
import {getBaseDataDivisor, getDataPoint} from "../../../../services/StatisticsService";

export const InvoiceStatisticsLineData = ({
    activeInvoiceId,
    activeKey,
    context,
    focusedDataKey,
    invoices,
    mapInvoiceDateToFirstDayOfMonth,
    maxDate,
    minDate,
    onHideTooltip,
    onItemSelected,
    onShowTooltip,
    selectedContractItems,
    selectedInvoiceId,
    selectedInvoiceItem,
    selectedTab,
    useAlternateArticleNames
}) => {

    const customDot = ({cx, cy, key, payload, color}) => {
        const isSelected = selectedInvoiceItem
            && selectedInvoiceId !== undefined
            && selectedInvoiceId !== null
            && [selectedInvoiceItem?.articleNumber, selectedInvoiceItem?.articleName, selectedInvoiceItem?.alternateDescription].includes(key)
            && payload.invoiceId === selectedInvoiceId;
        return (
            <circle
                key={key + cx + cy}
                cx={cx}
                cy={cy}
                r={isSelected ? 8 : 4}
                stroke={color}
                strokeWidth={3}
                fill={white}/>
        );
    }

    const customActiveDot = ({cx, cy, key, payload, color}) => {
        const isActive = key === activeKey && payload.invoiceId === activeInvoiceId;
        return (
            <circle
                cx={cx}
                cy={cy}
                r={8}
                stroke={isActive ? color : "transparent"}
                strokeWidth={3}
                fill={isActive ? white : "transparent"}
                onMouseOver={(_) => onShowTooltip(key, payload.invoiceId)}
                onMouseLeave={(_) => onHideTooltip()}
                onClick={() => onItemSelected(key, payload.invoiceId)}/>
        );
    }

    const getLine = (invoiceId, key, invoiceItem, colorProvider) => {
        let articleName;
        if (useAlternateArticleNames && invoiceItem?.alternateDescription) {
            articleName = invoiceItem.alternateDescription;
        } else {
            articleName = invoiceItem?.articleName ?? key;
        }

        let color = colorProvider.getColor(key);

        return (
            <Line
                key={key + invoiceId}
                hide={focusedDataKey && key !== focusedDataKey}
                connectNulls={true}
                type="linear"
                name={articleName}
                dataKey={key}
                strokeWidth={3}
                stroke={color}
                yAxisId={selectedTab}
                dot={({cx, cy, payload}) => customDot({cx, cy, payload, key, color})}
                activeDot={({cx, cy, payload}) => customActiveDot({cx, cy, payload, key, color})}
            />
        );
    }

    let data = [];
    let lines = [];
    let colorProvider = new ColorProvider();

    const sortedInvoices = invoices?.filter(invoice => {

        const invoiceDateMappedToFirstDay = mapInvoiceDateToFirstDayOfMonth(invoice.invoiceDate).getTime();

        return invoiceDateMappedToFirstDay >= minDate && invoiceDateMappedToFirstDay <= maxDate;

    })?.sort((a,b) => a.invoiceDate - b.invoiceDate);

    if (!minDate || !maxDate || selectedContractItems.length === 0
        || !sortedInvoices || sortedInvoices.length === 0) {
        return [null, null];
    }

    sortedInvoices.forEach(invoice => {
        const numberToItemMap = new Map();
        const nameToItemMap = new Map();
        invoice.invoiceItems.forEach((invoiceItem) => {
            if (!!invoiceItem.articleNumber) {
                numberToItemMap.set(invoiceItem.articleNumber, invoiceItem);
            } else {
                nameToItemMap.set(invoiceItem.articleName, invoiceItem);
            }
        });

        const dataPoints = {invoiceId: invoice.id};

        selectedContractItems.forEach(selectedItem => {
            const divisor = getBaseDataDivisor(context, invoice.id, invoices, selectedTab);

            selectedItem.articleNumbers.forEach((articleNumber) => {
                let invoiceItem = numberToItemMap.get(articleNumber);
                dataPoints[articleNumber] = getDataPoint(invoiceItem, divisor, selectedTab);
                lines.push(getLine(invoice.id, articleNumber, invoiceItem, colorProvider));
            });

            if (!selectedItem.articleNumbers) {
                const key = selectedItem.item.name;
                let invoiceItem = nameToItemMap.get(key);
                dataPoints[key] = getDataPoint(invoiceItem, divisor, selectedTab);
                lines.push(getLine(invoice.id, key, invoiceItem, colorProvider));
            }
        });

        data.push(dataPoints);
    });
    return [data, lines];
}
