import { Checkbox, Grid, MenuItem, Select, Tooltip } from "@material-ui/core";
import { useContext } from "react";
import { useIntl } from "react-intl";
import {CHART_FILTER_OPTIONS, DIALOG_TYPE_INFO, INVOICE_CONFLICT_TYPE, PriceType, USE_ALTERNATE_ARTICLE_NAMES} from "../../../../util/Constants";
import { GeneralContext } from "../../../contexts/GeneralContext";
import TexisionDialog from "../../../uiLibrary/TexisionDialog";
import {getActiveOperation} from "../../../../services/OperationService";

const InvoiceStatisticsItemSelection = ({
    bufferedContractItems,
    cancelSelection,
    filter,
    invoices,
    onFilterChanged,
    onItemChecked,
    projectIdToContractItemsMap,
    saveSelection,
    showItemSelection
}) => {

    const intl = useIntl();
    const context = useContext(GeneralContext);

    const activeProjectId = getActiveOperation(useContext(GeneralContext))?.activeProject?.id;

    const getPriceTypesByChartFilter = (filter) => {
        switch (filter) {
            case CHART_FILTER_OPTIONS.RENTAL_LINEN: return [PriceType.ARTICLE_QUANTITY, PriceType.ARTICLE_WEEKLY];
            case CHART_FILTER_OPTIONS.CUSTOMER_ARTICLES: return [PriceType.CUSTOMER_ARTICLE];
            case CHART_FILTER_OPTIONS.RESIDENTS_LAUNDRY: return [PriceType.RESIDENTS_LAUNDRY];
            case CHART_FILTER_OPTIONS.OPERATING_RESOURCES: return [PriceType.OPERATING_RESOURCE];
            case CHART_FILTER_OPTIONS.SPECIAL_SERVICES: return [PriceType.SPECIAL_SERVICE];
            default: return [];
        }
    }

    const getItemsByChartFilter = (filter, itemsMap, unknownArticles, wrongPriceArticles, duplicatedArticles, missingArticles) => {
        let itemsByCategory = Array.from(itemsMap.get(activeProjectId)).filter(([articleNumber, contractItem]) =>
            getPriceTypesByChartFilter(filter).includes(contractItem.type));
        switch (filter) {
            case CHART_FILTER_OPTIONS.ALL:
                return [...unknownArticles, ...Array.from(itemsMap.get(activeProjectId))];
            case CHART_FILTER_OPTIONS.RENTAL_LINEN:
            case CHART_FILTER_OPTIONS.CUSTOMER_ARTICLES:
            case CHART_FILTER_OPTIONS.RESIDENTS_LAUNDRY:
            case CHART_FILTER_OPTIONS.OPERATING_RESOURCES:
            case CHART_FILTER_OPTIONS.SPECIAL_SERVICES:
                return itemsByCategory;
            case CHART_FILTER_OPTIONS.WRONG_PRICE_ARTICLES:
                return wrongPriceArticles;
            case CHART_FILTER_OPTIONS.DUPLICATED_ARTICLES:
                return duplicatedArticles;
            case CHART_FILTER_OPTIONS.MISSING_ARTICLES:
                return missingArticles;
            case CHART_FILTER_OPTIONS.UNKNOWN_ARTICLES:
                return unknownArticles;
            default:
                return [];
        }
    }

    const itemSelection = () => {

        if (!showItemSelection) {
            return null;
        }

        let itemsMap = projectIdToContractItemsMap;

        if (!itemsMap 
            || itemsMap.size === 0 
            || !itemsMap.get(activeProjectId) 
            || itemsMap.get(activeProjectId).size === 0) {
            return null;
        }

        let unknownArticles = [];
        let wrongPriceArticles = [];
        let duplicatedArticles = [];
        let missingArticles = [];

        for (let invoice of invoices) {
            for (let invoiceItem of invoice.invoiceItems) {
                let pseudoContractItem = {
                    id: invoiceItem.articleName,
                    type: null,
                    price: null,
                    item: {name: invoiceItem.articleName, amount: invoiceItem.amount},
                    articleNumbers: [invoiceItem.articleNumber]
                };

                const correspondingContractItem = Array.from(itemsMap.get(activeProjectId))
                    ?.find(([articleNumber, item]) => pseudoContractItem?.articleNumbers?.includes(articleNumber));
                if (correspondingContractItem) {
                    pseudoContractItem.alternateDescription = correspondingContractItem[1].alternateDescription;
                }

                if (invoiceItem.conflictType === INVOICE_CONFLICT_TYPE.UNKNOWN_ARTICLE 
                    && !unknownArticles.find(([articleNumber, contractItem]) => contractItem.item.name === invoiceItem.articleName)) {

                    unknownArticles.push([null, pseudoContractItem]);

                } else if (invoiceItem.conflictType === INVOICE_CONFLICT_TYPE.WRONG_PRICE
                    && !wrongPriceArticles.find(([articleNumber, contractItem]) => contractItem.item.name === invoiceItem.articleName)) {

                        wrongPriceArticles.push([null, pseudoContractItem]);

                } else if (invoiceItem.conflictType === INVOICE_CONFLICT_TYPE.DUPLICATED_ARTICLE
                    && !duplicatedArticles.find(([articleNumber, contractItem]) => contractItem.item.name === invoiceItem.articleName)) {

                        duplicatedArticles.push([null, pseudoContractItem]);

                } else if (invoiceItem.conflictType === INVOICE_CONFLICT_TYPE.MISSING_ARTICLE
                    && !missingArticles.find(([articleNumber, contractItem]) => contractItem.item.name === invoiceItem.articleName)) {

                        missingArticles.push([null, pseudoContractItem]);
                }
            }
        }

        let filteredList = getItemsByChartFilter(filter, itemsMap, unknownArticles, wrongPriceArticles, duplicatedArticles, missingArticles);

        filteredList = filteredList.map(([articleNumber, contractItem]) => {
            switch (contractItem.type) {
                case PriceType.ARTICLE_QUANTITY:
                case PriceType.ARTICLE_WEEKLY:
                    contractItem.name = intl.formatMessage({id: "constants.ArticleCategory." 
                        + contractItem.item.articleVo.articleCategory});
                    break;
                case PriceType.CUSTOMER_ARTICLE:
                    contractItem.name = contractItem.item.description;
                    break;
                case PriceType.RESIDENTS_LAUNDRY:
                case PriceType.OPERATING_RESOURCE:
                case PriceType.SPECIAL_SERVICE:
                default:
                    contractItem.name = contractItem.item.name;
                    break;
            }
            return [articleNumber, contractItem];
        });
        
        return (
            <Grid container alignItems="center">

                <Grid item xs={12} style={{marginBottom: 20}}>

                        <Select 
                            labelId="chart-select"
                            value={filter}
                            variant="outlined">

                            {Object.values(CHART_FILTER_OPTIONS).filter(option => !!getItemsByChartFilter(option, itemsMap,
                                unknownArticles, wrongPriceArticles, duplicatedArticles, missingArticles)
                                .length).map(option =>
                                <MenuItem 
                                    key={option}
                                    value={option}
                                    onClick={() => onFilterChanged(option)}>
                                    {intl.formatMessage({id: "cockpit.statistics.filterOption." + option})}
                                </MenuItem>
                            )}
                        </Select>

                </Grid>

                {filteredList.sort((a,b) => a[1].name.localeCompare(b[1].name)).map(([articleNumber, contractItem]) => {

                    const checked = !!bufferedContractItems.find(i => JSON.stringify(i) === JSON.stringify(contractItem));

                    const checkBoxDisabled = !checked && bufferedContractItems.length >= 10;

                    let articleName;

                    if (!!context.getUserStateValue(USE_ALTERNATE_ARTICLE_NAMES)) {
                        if (contractItem.alternateDescription) {
                            articleName = contractItem.alternateDescription;
                        } else if (contractItem.name) {
                            articleName = contractItem.name;
                        } else if (contractItem.item?.articleVo?.description) {
                            articleName = contractItem.item?.articleVo?.description;
                        } else {
                            articleName = contractItem.name;
                        }
                    } else {
                        if (contractItem.item?.articleVo?.description) {
                            articleName = contractItem.item?.articleVo?.description;
                        } else if (contractItem.name) {
                            articleName = contractItem.name;
                        } else if (contractItem.alternateDescription) {
                            articleName = contractItem.alternateDescription;
                        } else {
                            articleName = contractItem.name;
                        }
                    }

                    return (
                        <Grid item xs={12} sm={6} md={4} lg={3} key={articleNumber + contractItem.name}>

                            <Grid container alignItems="center">

                                <Grid item>
                                    <Tooltip 
                                        title={checkBoxDisabled 
                                            ? intl.formatMessage({id: "cockpit.statistics.itemSelection.maxItems.tooltip"}) 
                                            : ""}>
                                        <div>
                                            <Checkbox
                                                color="primary"
                                                disabled={checkBoxDisabled}
                                                onChange={(_, checked) => onItemChecked(contractItem, checked)}
                                                checked={!!bufferedContractItems.find(i => JSON.stringify(i) === JSON.stringify(contractItem))}/>
                                        </div>
                                    </Tooltip>
                                </Grid>

                                <Grid item xs>
                                    {articleName}
                                </Grid>

                            </Grid>
            
                        </Grid>
                    );
                    
                })}

            </Grid>
        );
    }

    return (
        <TexisionDialog
            type={DIALOG_TYPE_INFO}
            open={showItemSelection}
            style={{}}
            titleId="cockpit.statistics.itemSelection.title"
            subtitleId="cockpit.statistics.itemSelection.subtitle"
            actionId="commons.save.button"
            cancelId="commons.cancel.button"
            onAction={() => saveSelection()}
            onCancel={() => cancelSelection()}
            content={itemSelection()}/>
    );
}

export {
    InvoiceStatisticsItemSelection
}
