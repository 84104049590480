import { Box, Tab, Tabs } from "@material-ui/core";
import { useIntl } from "react-intl";
import {primaryColor} from "../../../../util/ColorTheme";

const InvoiceStatisticsTabs = ({onTabChange, selectedContractItems, selectedTab}) => {

    const intl = useIntl();

    if (!selectedContractItems || selectedContractItems.length === 0) {
        return null;
    }
    
    return (
        <Box sx={{borderBottom: 1, borderColor: 'divider', marginBottom: 25}}>
            <Tabs
                TabIndicatorProps={{style: {backgroundColor: primaryColor}}}
                value={selectedTab} 
                onChange={(_, newTab) => onTabChange(newTab)}
                style={{width: "100%"}}>
                <Tab fullWidth value="price" label={intl.formatMessage({id: "priceSheet.column.price"})}/>
                <Tab fullWidth value="pricePerCase" label={intl.formatMessage({id: "priceSheet.column.pricePerCase"})}/>
                <Tab fullWidth value="pricePerDay" label={intl.formatMessage({id: "priceSheet.column.pricePerDay"})}/>
                <Tab fullWidth value="amount" label={intl.formatMessage({id: "priceSheet.column.amount"})}/>
                <Tab fullWidth value="amountPerCase" label={intl.formatMessage({id: "priceSheet.column.amountPerCase"})}/>
                <Tab fullWidth value="amountPerDay" label={intl.formatMessage({id: "priceSheet.column.amountPerDay"})}/>
            </Tabs>
        </Box>
    );
}

export {
    InvoiceStatisticsTabs
}
