import { useContext } from "react";
import { FormattedMessage } from "react-intl";
import { GeneralContext } from "../../../contexts/GeneralContext";
import {getActiveOperation} from "../../../../services/OperationService";

const InvoiceDiagramEmptyMessage = ({
    hasNoPeriod, 
    invoices,
    mapInvoiceDateToFirstDayOfMonth,
    projectIdToContractItemsMap, 
    selectedContractItems,
    selectedMinDate, 
    selectedMaxDate
}) => {

    const activeProjectId = getActiveOperation(useContext(GeneralContext))?.activeProject?.id;

    if (selectedContractItems && selectedContractItems.length > 0) {
        return null;
    }

    let message;

    if (!projectIdToContractItemsMap 
        || projectIdToContractItemsMap.size === 0
        || !projectIdToContractItemsMap.get(activeProjectId) 
        || projectIdToContractItemsMap.get(activeProjectId).size === 0) {

        message = <FormattedMessage id="cockpit.statistics.noItems"/>;

    } else if (!invoices || invoices.length === 0) {

        message = <FormattedMessage id="cockpit.statistics.noInvoices"/>;

    } else if (hasNoPeriod) {

        message = <FormattedMessage id="cockpit.statistics.noPeriod"/>;

    } else if (!invoices.find(invoice => 
        mapInvoiceDateToFirstDayOfMonth(invoice.invoiceDate).getTime() >= selectedMinDate 
        && mapInvoiceDateToFirstDayOfMonth(invoice.invoiceDate).getTime() <= selectedMaxDate)) {

        message = <FormattedMessage id="cockpit.statistics.noInvoicesInPeriod"/>;

    } else {

        message = <FormattedMessage id="cockpit.statistics.noSelectedItems"/>;

    }

    return (
        <div style={{width: "90%", height: 200, padding: 40, display: "flex", justifyContent: "center", alignItems: "center"}}>
            {message}
        </div>
    );
}

export {
    InvoiceDiagramEmptyMessage
}
