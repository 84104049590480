import React, {Component} from 'react';
import {Grid, Typography} from "@material-ui/core";
import {FormattedMessage, injectIntl} from "react-intl";
import ArticleNameSwitch from "../../../share/ArticleNameSwitch";
import InvoiceStatisticsContainer from "./InvoiceStatisticsContainer";
import {white} from "../../../../util/ColorTheme";
import InvoiceStatisticsItemDetails from "./InvoiceStatisticsItemDetails";
import InvoiceStatisticsDetails from "./InvoiceStatisticsDetails";
import {SimpleTextCard} from "../../../uiLibrary/SimpleTextCard";
import {latestActivePayment} from "../../../../services/PaymentService";
import {LICENSE_TYPES} from "../../../../util/Constants";
import {getActiveOperation} from "../../../../services/OperationService";
import {getAsyncCatch} from "../../../../services/BackendService";
import {getInvoices} from "../../../../services/InvoiceService";
import {GeneralContext} from "../../../contexts/GeneralContext";
import {withSnackbar} from "notistack";

class InvoiceStatistics extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.state = {
            projectIdToContractItemsMap: new Map(),
            invoices: [],
            selectedInvoice: null,
            selectedInvoiceItem: null,
            hasDiagramData: false
        }
    }

    async componentDidMount() {
        if (latestActivePayment(this.context, LICENSE_TYPES.COOPERATION_LICENSE)) {
            await this.loadContractItemsAndInvoices(getActiveOperation(this.context)?.activeProject?.id);
        }
    }

    loadContractItemsAndInvoices = async(projectId) => {
        const contractItems = await getAsyncCatch(this.context, "/invoice/contractitems/" + projectId, this.props);
        if (contractItems && contractItems !== true) {
            const projectIdToContractItemsMap = this.state.projectIdToContractItemsMap;
            projectIdToContractItemsMap.set(projectId, new Map(Object.entries(contractItems)));
            let invoices = await getInvoices(this.context, this.props);
            if (invoices?.length && invoices !== true) {
                for (let invoice of invoices) {
                    for (let invoiceItem of invoice.invoiceItems) {
                        const correspondingContractItem = projectIdToContractItemsMap?.get(projectId)?.get(invoiceItem.articleNumber);
                        invoiceItem.alternateDescription = correspondingContractItem?.alternateDescription;
                    }
                }
            }
            this.setState({invoices: invoices && invoices !== true ? invoices : [], projectIdToContractItemsMap});
        }
    }

    onItemSelected = async(key, invoiceId) => {
        if (!key || invoiceId === null || invoiceId === undefined) {
            this.setState({selectedInvoice: null, selectedInvoiceItem: null});
            return;
        }
        const selectedInvoice = this.state.invoices?.find(invoice => invoice.id === invoiceId);
        const selectedInvoiceItem = selectedInvoice?.invoiceItems?.find(item => item.articleNumber === key || item.articleName === key);

        if (!this.state.projectIdToContractItemsMap?.get(selectedInvoice?.projectId)) {
            await this.loadContractItemsAndInvoices(selectedInvoice?.projectId);
        }

        this.setState({selectedInvoice, selectedInvoiceItem});
    }

    render() {
        const { hasDiagramData, invoices, projectIdToContractItemsMap, selectedInvoice, selectedInvoiceItem } = this.state;

        const selectedContractItem = selectedInvoiceItem?.articleNumber
            && selectedInvoice?.projectId !== null
            && selectedInvoice?.projectId !== undefined
                ? projectIdToContractItemsMap?.get(selectedInvoice.projectId)?.get(selectedInvoiceItem.articleNumber)
                : null;

        const hasCooperationLicense = latestActivePayment(this.context, LICENSE_TYPES.COOPERATION_LICENSE);

        return (
            <>
                <Typography variant="h1">
                    <FormattedMessage id="cockpit.statistics.invoices.title"/>
                </Typography>

                <Typography variant="subtitle1">
                    <FormattedMessage id="cockpit.statistics.invoices.subtitle"/>
                </Typography>

                <SimpleTextCard show={!hasCooperationLicense} textId="cockpit.statistics.noBidder"/>

                {hasCooperationLicense && <>

                    <Grid container key={"invoice-statistics-" + invoices?.length} spacing={2}>

                        <ArticleNameSwitch/>

                        <Grid item xs={12}>
                            <InvoiceStatisticsContainer
                                invoices={invoices}
                                projectIdToContractItemsMap={projectIdToContractItemsMap}
                                selectedInvoiceId={selectedInvoice?.id}
                                selectedInvoiceItem={selectedInvoiceItem}
                                onItemSelected={(articleNumber, invoiceId) => this.onItemSelected(articleNumber, invoiceId)}
                                onDiagramDataChange={(hasDiagramData) => this.setState({hasDiagramData})}/>
                        </Grid>

                        {hasDiagramData && <>

                            <Grid item xs={12} sm={12} md={12} lg={6} style={{backgroundColor: white, minWidth: 300}}>
                                <InvoiceStatisticsItemDetails
                                    contractItem={selectedContractItem}
                                    invoiceItem={selectedInvoiceItem}/>
                            </Grid>

                            <Grid item/>

                            <Grid item xs={12} sm={12} md={12} lg style={{backgroundColor: white, minWidth: 300}}>
                                <InvoiceStatisticsDetails
                                    invoice={selectedInvoice}/>
                            </Grid>
                        </>}

                    </Grid>

                </>}
            </>
        );
    }
}

export default injectIntl(withSnackbar(InvoiceStatistics));
