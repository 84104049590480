import TexisionDialog from "../uiLibrary/TexisionDialog";
import {DIALOG_TYPE_INFO, LICENSE_TYPES, OFFER_STATUS_UNDEFINED} from "../../util/Constants";
import PublishBidderSelection from "../navigation/tender/PublishBidderSelection";
import React, {Component} from "react";
import {GeneralContext} from "../contexts/GeneralContext";
import {getBiddersOfTender, inviteBiddersToTender, isAdmin, isProjectAdmin} from "../../services/UserService";
import {getActiveProject} from "../../services/ProjectService";
import {FormattedMessage, injectIntl} from "react-intl";
import {withSnackbar} from "notistack";
import {Header} from "../uiLibrary/Header";
import {bodyBackgroundColor, texisionFontColorDark} from "../../util/ColorTheme";
import Paper from "@material-ui/core/Paper";
import MaterialTable from "material-table";
import {latestActivePayment} from "../../services/PaymentService";
import {Card, CardContent} from "@mui/material";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import {loadOffers} from "../../services/OfferService";
import {getProcedure} from "../../services/ProcedureService";
import {isFirstDeadlineOver} from "../../util/ProcedureUtil";
import {Tooltip} from "@material-ui/core";

class InvolvedBidders extends Component {

    static contextType = GeneralContext;

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            showBidderSelectionDialog: false,
            selectedBidders: [],
            invitedBidders: [],
            offers: [],
            procedure: null
        };
    }

    async componentDidMount() {
        await this.loadData();
    }

    loadData = async() => {
        this.setState({loading: true});
        const invitedBidders = await getBiddersOfTender(this.context, this.props);
        const offers = await loadOffers(this.context);
        const procedure = await getProcedure(this.context, this.props, this.context.appData.offerProjectId);

        if (!!invitedBidders) {
            invitedBidders.forEach(bidder => {
                let offerStatus = offers.find(o => o.username === bidder.username)?.status ?? OFFER_STATUS_UNDEFINED;
                bidder.offerStatus = this.props.intl.formatMessage({id: "bidderSelection.offerStatus.table." + offerStatus});
            })
        }

        this.setState({loading: false, invitedBidders, offers, procedure});
    }

    handleBidderSelectionChange = (select, bidder) => {
        let selectedBidders = this.state.selectedBidders;
        let invitedBidders = this.state.invitedBidders;
        if (select &&
                !selectedBidders?.find(b => b.id === bidder?.id) &&
                !invitedBidders.find(b => b.id === bidder?.id)) {
            selectedBidders.push(bidder);
        } else if (!select) {
            selectedBidders = selectedBidders?.filter(b => b.id !== bidder?.id);
        }
        this.setState({selectedBidders});
    }

    inviteBidders = async() => {
        this.setState({loading: true});
        let offerProjectId = this.context.appData.offerProjectId;
        await inviteBiddersToTender(this.context, this.props, offerProjectId, this.state.selectedBidders.map(b => b.id));
        this.setState({showBidderSelectionDialog: false, loading: false, selectedBidders: []});
        await this.loadData();
    }

    inviteButton = () => {
        const isClosed = isFirstDeadlineOver(this.state.procedure);
        const button = <Button
            variant="contained"
            color="primary"
            onClick={() => this.setState({showBidderSelectionDialog: true})}
            disabled={this.context.offline || isClosed}>
            <FormattedMessage id="bidderSelection.invite.bidder.button.title"/>
        </Button>

        if (isClosed) {
            return <Tooltip title={this.props.intl.formatMessage({id : "bidderSelection.invite.bidder.button.tooltip"})}>
                <div>{button}</div>
            </Tooltip>;
        } else {
            return button;
        }
    }

    render() {
        if (!isProjectAdmin(this.context.currentUser) && !isAdmin(this.context.currentUser)) {
            return <div/>;
        }

        const project = getActiveProject(this.context);
        if (!project) {
            return <div/>;
        }

        const columns = [
            {title: this.props.intl.formatMessage({id: "bidderSelection.company.table.header"}), field: "company"},
            {title: this.props.intl.formatMessage({id: "useradmin.email"}), field: "username"},
            {title: this.props.intl.formatMessage({id: "useradmin.firstname"}), field: "firstName"},
            {title: this.props.intl.formatMessage({id: "useradmin.lastname"}), field: "lastName"},
            {title: this.props.intl.formatMessage({id: "bidderSelection.offerStatus.table.header"}), field: "offerStatus"},
        ];

        const bidders = this.state.invitedBidders ? this.state.invitedBidders?.sort((a,b) => a.username?.localeCompare(b.username)) : [];
        const hasPermission = isAdmin(this.context.currentUser) || latestActivePayment(this.context, LICENSE_TYPES.TENDER_LICENSE);

        return <>
            <Header
                titleId="involved.bidders.title"
                subtitleId={"involved.bidders.subtitle"}/>

            <TexisionDialog
                type={DIALOG_TYPE_INFO}
                size="lg"
                open={this.state.showBidderSelectionDialog}
                titleId="navBar.bidderSelection.alternative.title"
                subtitleId="navBar.bidderSelection.subtitle"
                content={<PublishBidderSelection
                    isRepublishing={true}
                    projectName={project.name}
                    selectedBidders={this.state.selectedBidders}
                    onBidderSelect={(select, bidder) => this.handleBidderSelectionChange(select, bidder)}/>}
                replacements={{tender: project.name}}
                cancelId="commons.cancel.button"
                actionId="navBar.bidderSelection.button.title"
                onCancel={() => this.setState({
                    showBidderSelectionDialog: false,
                    confirmPublishing: false,
                    comment: "",
                    selectedBidders: []
                })}
                onAction={() => this.inviteBidders()}
                actionDisabled={!this.state.selectedBidders?.length || this.state.loading}/>


            {hasPermission &&
                <Card className="child" style={{minWidth: 200}}>
                    <CardContent>
                        <Grid container justifyContent="flex-end" alignItems="center">
                            <Grid item style={{whiteSpace: "nowrap"}}>
                                {this.inviteButton()}
                            </Grid>
                        </Grid>

                        <MaterialTable
                            columns={columns}
                            data={bidders}
                            options={{
                                padding: "dense",
                                grouping: false,
                                filtering: false,
                                search: false,
                                paging: false,
                                draggable: false,
                                headerStyle: {
                                    fontWeight: 'bold',
                                    backgroundColor: bodyBackgroundColor,
                                    color: texisionFontColorDark
                                },
                                showTitle: false,
                                actionsColumnIndex: -1
                            }}
                            localization={{
                                body: {
                                    emptyDataSourceMessage: this.props.intl.formatMessage({id: "useradmin.noData"})
                                }
                            }}
                            components={{
                                Container: props => (<Paper {...props} key={this.props.type} elevation={0}/>)}
                            }
                        />
                </CardContent>
            </Card>}
        </>
    }
}
export default injectIntl(withSnackbar(InvolvedBidders));